import { createBrowserRouter, Navigate, Outlet } from "react-router-dom";
import React from "react";
import {
  BookingEditContainer,
  BookingsListContainer,
  DashboardContainer,
  LoginContainer,
  MasterContainer,
  ReportsContainer,
} from "../containers";
import SettingsMasterPage from "../containers/Settings/SettingsMasterPage";
import TileCompaniesPickerContainer from "../containers/CompanySelect/TileCompaniesPickerContainer";
import SettingsProfileContainer from "../containers/Settings/ProfileContainer";
import SettingsHoursContainer from "../containers/Settings/HoursContainer";
import SettingsContactsContainer from "../containers/Settings/ContactsContainer";
import SettingsEmployeesContainer from "../containers/Settings/EmployeesContainer";
import SettingsServicesContainer from "../containers/Settings/ServicesContainer";
import SettingsServiceEditContainer from "../containers/ServiceEdit/ServiceEditContainer";
import SettingsEmployeeFormContainer from "../containers/Employees/EmployeeFormContainer";
import AuthenticateContainer from "../containers/Authenticate/AuthenticateContainer";
import HelpMasterPage from "../containers/Help/HelpMasterPage";
import FaqSearchResults from "../containers/Faqs/FaqsSearchResults";
import FaqsContainer from "../containers/Faqs/FaqsContainer";
import FaqsAnswer from "../containers/Faqs/FaqsAnswer";
import FaqsTopic from "../containers/Faqs/FaqsTopic";
import HelpTermsPage from "../containers/Help/HelpTermsPage";
import HelpContactPage from "../containers/Help/HelpContactPage";
import getPrismicRoutes from "../prismic/routes";
import NotFound from "../components/NotFound";
import { getCompaniesBySlugMap } from "../redux/modules/companies";
import { store } from "../providers/redux";
import { authSetCompanyId } from "../redux/modules/auth";
import AvedaSignupPageContainer from "../containers/SupplierSignup/aveda/AvedaSignupPageContainer";
import { STORE_AUTH_COMPANY_ID } from "./constants";
import ForgotPasswordContainer from "../containers/ResetPassword/ForgotPasswordContainer";
import ChangePasswordContainer from "../containers/ResetPassword/ChangePasswordContainer";
import HelpPrivacyPage from "../containers/Help/HelpPrivacyPage"

const getCompanyIdFromCookie = () => {
  const cookiesCompanyId = window.localStorage.getItem(STORE_AUTH_COMPANY_ID);
  // sometimes in the cookies the string 'null' gets saved
  if (
    cookiesCompanyId &&
    cookiesCompanyId !== "null" &&
    cookiesCompanyId !== "0"
  ) {
    // we restore here the previously selected company
    return window.localStorage.getItem(STORE_AUTH_COMPANY_ID);
  }
  return null;
};

const getCompanyIdFromParams = (params) => {
  const companiesBySlugMap = store
    ? getCompaniesBySlugMap(store.getState())
    : new Map();
  if (
    Object.hasOwnProperty("companySlug") &&
    // The loader gets called prior to the redux state being ready, do nothing unless the map is populated
    companiesBySlugMap.count() > 0
  ) {
    const companySlug = params.companySlug;

    if (!companiesBySlugMap.has(companySlug)) {
      throw new Error("Invalid company");
    }

    return companiesBySlugMap.getIn([companySlug, "id"]);
  }

  return null;
};

const getCompanyId = ({ params }) => {
  // If there's a company in the slug, it takes precedence
  let companyId = getCompanyIdFromParams(params);

  // else.. if there's a company in the cookie, use that
  if (!companyId) {
    companyId = getCompanyIdFromCookie();
  }

  if (store && companyId) {
    store.dispatch(authSetCompanyId(companyId));
  }

  return companyId;
};

const routes = [
  {
    path: "*",
    element: <NotFound />,
  },
  {
    path: "/",
    element: <Outlet />,
    children: [
      {
        index: true,
        element: (
          <MasterContainer menuActive="home">
            <DashboardContainer />
          </MasterContainer>
        ),
      },
      {
        path: "bookings",
        element: (
          <MasterContainer menuActive="bookings">
            <Outlet />
          </MasterContainer>
        ),
        children: [
          {
            index: true,
            element: <Navigate to={"/bookings/pending"} replace={true} />,
          },
          {
            path: ":state?",
            loader: ({ params }) => {
              const state = params.state;
              if (state && !state.match("^complete|pending$")) {
                throw new Response("Not found", { status: 404 });
              }
              return null;
            },
            element: <BookingsListContainer />,
          },
          {
            path: "detail",
            element: <BookingEditContainer />,
          },
        ],
      },
      {
        path: "help",
        element: (
          <MasterContainer menuActive="help">
            <HelpMasterPage />
          </MasterContainer>
        ),
        children: [
          {
            path: "faqs",
            // element: <HelpFaqsPage />,
            element: <FaqsContainer />,
          },
          {
            path: "faqs/search/:query",
            element: <FaqSearchResults />,
          },
          {
            path: "faqs/:faqTopic/:faq",
            element: <FaqsAnswer />,
          },
          {
            path: "faqs/:faqTopic",
            element: <FaqsTopic />,
          },
          {
            path: "terms",
            element: <HelpTermsPage />,
          },
          {
            path: "privacy-policy",
            element: <HelpPrivacyPage />,
          },
          {
            path: "contact",
            element: <HelpContactPage />,
          },
        ],
      },
      {
        path: "reports",
        element: (
          <MasterContainer menuActive="reports">
            <ReportsContainer />
          </MasterContainer>
        ),
      },
      {
        path: "settings",
        element: (
          <MasterContainer menuActive="settings">
            <SettingsMasterPage />
          </MasterContainer>
        ),
        children: [
          {
            index: true,
            element: <TileCompaniesPickerContainer />,
          },
          {
            path: "profile",
            element: <SettingsProfileContainer />,
          },
          {
            path: "hours",
            element: <SettingsHoursContainer />,
          },
          {
            path: "contacts",
            element: <SettingsContactsContainer />,
          },
          {
            path: "employees",
            element: <Outlet />,
            children: [
              {
                index: true,
                element: <SettingsEmployeesContainer />,
              },
              {
                path: ":companySlug/:employeeSlug/:profileSection?",
                element: <SettingsEmployeeFormContainer />,
                loader: ({ params }) => ({ companyId: getCompanyId(params) }),
              },
            ],
          },
          {
            path: "services",
            element: <Outlet />,
            children: [
              {
                index: true,
                element: <SettingsServicesContainer />,
              },
              {
                path: ":companySlug/:serviceSlug/:serviceSection?/:employeeSlug?",
                element: <SettingsServiceEditContainer />,
                loader: ({ params }) => ({ companyId: getCompanyId(params) }),
              },
            ],
          },
        ],
      },
      {
        path: "login",
        element: (
          <MasterContainer isPublic>
            <LoginContainer />
          </MasterContainer>
        ),
      },
      {
        path: "forgot-password",
        element: (
          <MasterContainer isPublic>
            <ForgotPasswordContainer />
          </MasterContainer>
        ),
      },
      {
        path: "reset-password/:id",
        element: (
          <MasterContainer isPublic>
            <ChangePasswordContainer />
          </MasterContainer>
        ),
      },
      {
        path: "authenticate",
        element: <AuthenticateContainer />,
      },
      ...[
        {
          path: "sign-up",
          software: null,
        },
        {
          path: "salonbiz-sign-up",
          software: "salonbiz",
        },
      ].map(({ path, software }) => ({
        path,
        loader: () => ({ software }),
        element: (
          <MasterContainer isPublic>
            {process.env.COMPANY === "aveda" ? (
              <AvedaSignupPageContainer />
            ) : (
              <NotFound />
            )}
          </MasterContainer>
        ),
      })),
      ...(await getPrismicRoutes()),
    ],
  },
];

export default createBrowserRouter(routes);
